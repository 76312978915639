import React, { Component } from 'react'
import { Router } from '@reach/router'

const Home = React.lazy(() => import('./pages/Home'))
const RetroShow = React.lazy(() => import('./pages/RetroShow'))

function NotFound() {
  return (
    <h1 className="text-center" style={{ paddingTop: '1rem' }}>
      Not Found
    </h1>
  )
}

export default function App() {
  return (
    <React.Suspense fallback={<div />}>
      <div className="container-fluid">
        <Router>
          <Home path="/" />
          <RetroShow path="/retros/:id" />
          <NotFound default />
        </Router>
      </div>
    </React.Suspense>
  )
}
